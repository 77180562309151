import React, { useEffect } from 'react';
import AppLayout from '../layout/AppLayout';
import Footer from '../layout/Footer';

import {
    HomeBanner,
    HomeSolutions,
    HomeProjects,
    HomeClients,
    HomeRoles,
    HomeFacts,
    HomeContact,
} from '../components/home';

// react-redux
import { useDispatch, useSelector } from 'react-redux';

// reducer
import { getIsLoadedStatus, updateIsLoaded } from '../redux/reducers/system.reducers';

// components
import InitialLoading from '../components/others/InitialLoading';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    const system = useSelector(getIsLoadedStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!system.isLoaded) {
            setTimeout(() => {
                dispatch(updateIsLoaded(true));
            }, 2000);
        }
    }, [system.isLoaded]);

    return (
        <>
            <Helmet>
                <title>Zipher Solutions</title>
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />

                <meta name="title" content="Zipher Solutions" />
                <meta
                    name="description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    name="keywords"
                    content="information technology, it, services, solutions, iot, software, hardware, development, networking, cloud infrastructure, services, web3, technology"
                />
                <meta
                    itemprop="image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />

                {/* <!-- Open Graph / Facebook --> */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ziphersolutions.com/" />
                <meta property="og:title" content="Zipher Solutions" />
                <meta
                    property="og:description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    property="og:image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />

                {/* <!-- Twitter --> */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ziphersolutions.com/" />
                <meta property="twitter:title" content="Zipher Solutions" />
                <meta
                    property="twitter:description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    property="twitter:image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />
            </Helmet>
            {!system.isLoaded ? (
                <InitialLoading />
            ) : (
                <AppLayout>
                    <HomeBanner />
                    <HomeSolutions />
                    <HomeProjects />
                    <HomeClients />
                    {/* <HomeRoles /> */}
                    {/* <HomeFacts /> */}
                    <HomeContact />
                    <Footer />
                </AppLayout>
            )}
        </>
    );
};

export default HomePage;
