import React, { useEffect } from 'react';
import icon_roles from '../../../static/svg/roles-icon-b.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const HomeRoles = () => {
    //
    useEffect(() => {
        gsap.from('.gsap_app_home_img ', {
            duration: 1,
            y: '10',
            scale: 0.6,
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.roles_home',
                start: 'top 60%',
                end: 'bottom 40%',
                // markers: true,
                toggleActions: 'play none none reverse',
            },
            stagger: 0.2,
        });
    }, []);
    //
    const iot_cards = [
        {
            img: icon_roles,
            alt: 'IoT Icon 1',
            title: 'Application1',
            desc: (
                <>
                    Description about this team. We can tell what this role have for the company.
                    Keep it to three to four lines.
                </>
            ),
        },
        {
            img: icon_roles,
            alt: 'IoT Icon 2',
            title: 'Application2',
            desc: (
                <>
                    Description about this team. We can tell what this role have for the company.
                    Keep it to three to four lines.
                </>
            ),
        },
        {
            img: icon_roles,
            alt: 'IoT Icon 3',
            title: 'Application3',
            desc: (
                <>
                    Description about this team. We can tell what this role have for the company.
                    Keep it to three to four lines.
                </>
            ),
        },
        {
            img: icon_roles,
            alt: 'IoT Icon 4',
            title: 'Application4',
            desc: (
                <>
                    Description about this team. We can tell what this role have for the company.
                    Keep it to three to four lines.
                </>
            ),
        },
        {
            img: icon_roles,
            alt: 'IoT Icon 5',
            title: 'Application5',
            desc: (
                <>
                    Description about this team. We can tell what this role have for the company.
                    Keep it to three to four lines.
                </>
            ),
        },
    ];

    return (
        <>
            <section className="roles d-flex-col align-items-center justify-content-center roles_home">
                <div className="roles_container  d-flex-col align-items-center justify-content-center">
                    <div className="roles_container_title text-center">
                        <h2>IoT is Everywhere</h2>
                        <p>
                            It can be in our cars, appliances, televisions, <br /> speakers, and
                            even our entire house.
                        </p>
                    </div>
                    <div className="roles_container_cards">
                        {iot_cards.map((card, i) => (
                            <div
                                key={i}
                                className="roles_container_cards_card d-flex-col align-items-center justify-content-center text-center"
                            >
                                <img className="gsap_app_home_img" src={card.img} alt={card.alt} />
                                <div className="text d-flex-col">
                                    <p>{card.title}</p>
                                    <p>{card.desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};
