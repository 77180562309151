import React, { useEffect } from 'react';

import brand_icon from '../../../static/svg/clients-brand-icon.svg';
import img_bga from '../../../static/svg/roles-bground-left.svg';
import img_bgb from '../../../static/svg/roles-bground-right.svg';
import vrcity_logo from '../../../static/svg/clients-vrcity-logo.svg';
import qbit_logo from '../../../static/svg/clients-qbit-logo.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const HomeClients = () => {
    useEffect(() => {
        gsap.from('.gsap_clients_home_img', {
            duration: 1,
            y: '10',
            scale: 0.3,
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.clients_home',
                start: 'top 70%',
                end: 'bottom 30%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
    }, []);

    const client_brands = [
        {
            img: vrcity_logo,
            alt: 'VR City',
        },
        {
            img: qbit_logo,
            alt: 'Qbit Tech',
        },
    ];

    return (
        <>
            <section className="clients d-flex-col align-items-center justify-content-center clients_home">
                <div className="clients_container d-flex-col align-items-center justify-content-center gap-4">
                    <div className="clients_container_title text-center">
                        <p>CLIENTS</p>
                        <h2>Trusted to deliver great outputs</h2>
                    </div>
                    <p className="clients_container_subtitle text-center">
                        Zipher Solutions has worked with start-ups to large leading companies in the
                        world.
                    </p>
                    <div className="clients_container_brands d-flex-row align-items-center justify-content-center">
                        {client_brands.map((brand, i) => (
                            <img
                                className="gsap_clients_home_img"
                                key={i}
                                src={brand.img}
                                alt={brand.alt}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};
