import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
//ROUTER
// import { useNavigate } from 'react-router-dom';
import { navigate } from 'gatsby';

import { Button } from 'antd';
import circuit_web from '../../../static/svg/banner-circuit-web.svg';
import circuit_mobile from '../../../static/svg/banner-circuit-mobile.svg';
import { CaretRightOutlined } from '@ant-design/icons';

//REDUX
import { homeContentActions } from '../../redux/reducers/home.reducers';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const HomeBanner = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListHome } = homeContentActions;
    const launchBuilder = () => {
        localStorage.setItem('builderMode', true);
        navigate('/builder');
        dispatch(
            updateListHome({
                builderConfig: {
                    builderMode: true,
                },
            }),
        );
    };

    useEffect(() => {
        gsap.from('.gsap_banner', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '#banner_home',
                start: 'top 50%',
                end: 'bottom 30%',
                // markers: true,
                toggleActions: 'play',
            },
            stagger: 0.3,
        });
    }, []);

    return (
        <>
            <section
                name="banner"
                id="banner_home"
                className="banner d-flex-col align-items-center justify-content-center"
            >
                <div className="banner_main d-flex-col align-items-center justify-content-center">
                    <p className="gsap_banner display_3 text-center">
                    Decode your ideas into the 
                        <span className="text-orange"> next big thing</span>
                    </p>
                    <p className="gsap_banner text-center">
                        Intelligent, modern, and effective solutions for software, hardware,
                        networking, cloud infrastructure, and IoT.
                    </p>
                    <div className="gsap_banner">
                        {/* <Button type="primary" onClick={launchBuilder}>
                            <div className="d-flex-row align-items-center gap-1-2">
                                Build My Idea
                                <CaretRightOutlined />
                            </div>
                        </Button> */}
                    </div>
                </div>
                <img src={circuit_web} alt="Zipher Banner Circuit" />
                <img src={circuit_mobile} alt="Zipher Banner Circuit" />
                {/* <div className='banner_arrow'></div> */}
            </section>
        </>
    );
};
