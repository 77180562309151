import React, { useEffect } from 'react';

import { Button } from 'antd';
import background_title from '../../../static/svg/solutions-title-background.svg';
import background_card from '../../../static/svg/solutions-card.svg';
import placeholder_icon from '../../../static/svg/solutions-icon.svg';

import img_bga from '../../../static/svg/roles-bground-left.svg';
import img_bgb from '../../../static/svg/roles-bground-right.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

export const HomeSolutions = () => {
    //
    useEffect(() => {
        gsap.from('.gsap_solutions_home ', {
            duration: 1,
            y: '10',
            scale: 0.6,
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.solutions_home',
                start: 'top 50%',
                end: 'bottom 30%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
    }, []);
    
    const cards = [
        {
            icon: placeholder_icon,
            title: 'IoT Systems',
            subtitle:
                "Boost your operations through systems designed to optimize their capabilities.",
        },
        {
            icon: placeholder_icon,
            title: 'System Design and Development',
            subtitle:
                "Transform manual, time-consuming tasks into automated, streamlined processes.",
        },
        {
            icon: placeholder_icon,
            title: 'Hardware Development',
            subtitle:
                "Tell us what tools you need and we will bring them to life.",
        },
        {
            icon: placeholder_icon,
            title: 'IoT Device',
            subtitle:
                "Maximize custom-made devices to help you monitor your business at ease. Who knows? It may be what you need, you just don’t know it yet.",
        },
    ];

    return (
        <>
            <section
                id="solutions"
                className="solutions d-flex-col align-items-center justify-content-center solutions_home"
            >

                <div className="solutions_content d-flex-col align-items-center justify-content-center">
                    <div className="solutions_content_title text-center">
                        <p>SOLUTIONS</p>
                        <h2>We got these for you</h2>
                        <img src={background_title} alt="Title Background" />
                    </div>
                    <div className="solutionsmain_content_cards gap-3 d-flex-row align-items-center justify-content-center">
                        {cards.map((card, i) => (
                            <div
                                index={i}
                                className="solutionsmain_content_card gsap_solu_solutions"
                            >
                                <div className="card_container text-center d-flex-col align-items-center justify-content-center">
                                    <img src={card.icon} alt="Icon Zipher" />
                                    <p>{card.title}</p>
                                    <p>{card.subtitle}</p>
                                    <img src={background_card} alt="Card Background" />
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <Button type="primary">Learn More</Button> */}
                </div>
                <div>
                    <img src={img_bgb} alt="Card Background" className='homesolutions-shape-1' />
                    <img src={img_bgb} alt="Card Background" className='homesolutions-shape-2' />
                </div>
            </section>
        </>
    );
};
